// f-sib -> fond-start-investor-button
export const ANALITYCS_InvestorFordStartInvestingButtonIds = (index: number) => {
	const ids = new Array(6).fill(null).map((_, i) => `f-sib-${i + 1}`)

	if (!ids[index]) {
		console.error('Обращение к несуществующему id', ids, index)
	}

	return ids[index]
}
