export enum PagesPath {
	root = '/',
	about = 'about',
	contacts = 'contacts',
	decision = 'decision',
	agreement = 'agreement',
	check = 'check',
	documents = 'documents',
	feedback = 'feedback',
	loan = 'loan',
	partners = 'partners',
	application = 'application',
	personal = 'personal',
	rp = 'rp',
	wow = 'wow',
	loanRefinance = 'loan-refinance',
	mfoRefinance = 'mfo-refinance',
	moneyForBusiness = 'money-for-business',
	poluchiteBezBankov = 'poluchite-bez-bankov',
	referralPartners = 'referral-partners',
	vseBudetHorosho = 'vse-budet-horosho',
	savingRef = 'saving_ref',
	investGift = 'invest_gift',
}

type PagesTitles = Record<PagesPath, { title: string; mainBlockTitle?: string }>

export const pagesTitles: PagesTitles = {
	[PagesPath.root]: {
		title: 'Главная',
		mainBlockTitle: '',
	},
	[PagesPath.feedback]: {
		title: 'Отзывы — Credit.Club',
	},
	[PagesPath.about]: {
		title: 'О компании Credit.Club',
	},
	[PagesPath.contacts]: {
		title: 'Контакты — Credit.Club',
	},
	[PagesPath.agreement]: {
		title: 'Согласие с обработкой персональных данных',
	},
	[PagesPath.check]: {
		title: 'Credit.Club | Проверка документов',
	},
	[PagesPath.documents]: {
		title: 'Документы — Credit.Club',
	},
	[PagesPath.loan]: {
		title: 'Деньги на любые цели по честной ставке',
		mainBlockTitle: 'Кредит под залог квартиры по низкой ставке',
	},
	[PagesPath.partners]: {
		title: 'Партнёрская программа Credit.Club',
	},
	[PagesPath.application]: {
		title: 'Заявка на кредит',
	},
	[PagesPath.personal]: {
		title: 'Деньги на любые цели по честной ставке',
		mainBlockTitle: 'Кредит под залог квартиры',
	},
	[PagesPath.rp]: {
		title: 'Деньги на любые цели по честной ставке',
	},
	[PagesPath.wow]: {
		title: 'Деньги на любые цели по честной ставке',
		mainBlockTitle: 'Деньги на любые цели по честной ставке',
	},
	[PagesPath.loanRefinance]: {
		title: 'Рефинансируйте кредиты с высокой ставкой под залог квартиры',
		mainBlockTitle: 'Рефинансируйте кредиты с высокой ставкой под залог квартиры',
	},
	[PagesPath.mfoRefinance]: {
		title: 'Рефинансируйте микрокредиты под залог квартиры',
		mainBlockTitle: 'Рефинансируйте микрокредиты под залог квартиры',
	},
	[PagesPath.moneyForBusiness]: {
		title: 'Кредиты для бизнеса',
		mainBlockTitle: 'Кредит для бизнеса под залог квартиры от 14,9% годовых',
	},
	[PagesPath.poluchiteBezBankov]: {
		title: 'Отказали в банке? У нас есть быстрое решение',
		mainBlockTitle: 'Отказали в банке? У нас есть быстрое решение',
	},
	[PagesPath.referralPartners]: {
		title: 'Реферальная программа Сredit.Club',
	},
	[PagesPath.vseBudetHorosho]: {
		title: 'Много кредитов, а платёж душит зарплату? Получите надёжное рефинансирование',
		mainBlockTitle: 'Много кредитов, а платёж душит зарплату? Получите надёжное рефинансирование',
	},
	[PagesPath.savingRef]: {
		title: 'Сбережения с гарантией выплат',
	},
	[PagesPath.decision]: {
		title: 'Решение по кредиту',
	},
	[PagesPath.investGift]: {
		title: 'Подарок, который зарабатывает.',
	},
}
