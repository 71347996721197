import { Component, FocusEvent, FormEvent } from 'react'
import { getAmountFromSteps, getStepsFromAmount } from '@blocks/BlockInvestCalculator/data'
import { breaksHundreds, isNotPositiveIntegerValue, removeSpaces } from '@shared/utils/helpers'

import { InputRangeOldProps } from './model'
import { View } from './view'
interface InputRangeOldState {
	inputValue: string
}

export class InputRange extends Component<InputRangeOldProps, InputRangeOldState> {
	state: InputRangeOldState = {
		inputValue: breaksHundreds(getAmountFromSteps(this.props.value)),
	}

	handleChange = (value: number | string) => {
		const { min, max, onChange } = this.props
		if (isNotPositiveIntegerValue(value)) {
			return
		}
		if (+value < min!) {
			onChange(min!)
			this.setState({ inputValue: breaksHundreds(getAmountFromSteps(min!)) })
			return
		}

		if (+value > max!) {
			onChange(max!)
			this.setState({ inputValue: breaksHundreds(getAmountFromSteps(max!)) })
			return
		}

		onChange(+value)
		this.setState({ inputValue: breaksHundreds(getAmountFromSteps(Number(+value))) })
	}

	handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
		const { min, max, onChange } = this.props
		const cleanStrValue = removeSpaces(e.currentTarget.value)
		if (isNotPositiveIntegerValue(cleanStrValue)) {
			onChange(min!)
			this.setState({ inputValue: breaksHundreds(getAmountFromSteps(min!)) })
			return
		}

		const numberValue = +cleanStrValue

		if (numberValue < getAmountFromSteps(min!)) {
			onChange(min!)
			this.setState({ inputValue: breaksHundreds(getAmountFromSteps(min!)) })
			return
		}

		if (numberValue > getAmountFromSteps(max!)) {
			onChange(max!)
			this.setState({ inputValue: breaksHundreds(getAmountFromSteps(max!)) })
			return
		}

		onChange(getStepsFromAmount(numberValue))
		this.setState({ inputValue: breaksHundreds(numberValue) })
	}

	handleInputChange = (e: FormEvent<HTMLInputElement>) => {
		const { min, max, onChange } = this.props
		const cleanStrValue = removeSpaces(e.currentTarget.value)
		this.setState({ inputValue: cleanStrValue })

		if (isNotPositiveIntegerValue(cleanStrValue)) {
			onChange(min!)
			return
		}

		const numberValue = +cleanStrValue

		if (numberValue < getAmountFromSteps(min!)) {
			onChange(min!)
			return
		}

		if (numberValue > getAmountFromSteps(max!)) {
			onChange(max!)
			return
		}

		onChange(getStepsFromAmount(numberValue))
	}

	componentDidUpdate(prevProps: InputRangeOldProps) {
		if (prevProps.min !== this.props.min && this.props.value < this.props.min!) {
			this.handleChange(this.props.min!)
		} else if (prevProps.max !== this.props.max && this.props.value > this.props.max!) {
			this.handleChange(this.props.max!)
		}
	}

	render() {
		const { inputValue } = this.state
		return View({
			...this.props,
			inputValue,
			handleChange: this.handleChange,
			handleInputBlur: this.handleInputBlur,
			handleInputChange: this.handleInputChange,
		})
	}
}
