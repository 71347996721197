import React, { ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import InputRange from 'react-input-range'
import cx from 'classnames'

import { StateProps } from './model'

import styles from './style.module.scss'

export const View = ({
	min = 50000, // 50 тыс
	max = 20000000, // 20 млн
	step = 50000, // 50 тыс
	value,
	inputValue,
	handleChange,
	handleInputChange,
	handleInputBlur,
	resultClassName,
	labelClassName,
	inputRangeClassName,
	trackClassName,
	disabled,
	activeTrackClassName,
	formatLabel = (value) => value,
}: PropsWithChildren<StateProps>) => {
	// переопределяем классы InputRange
	const classNames = {
		inputRange: cx(styles.inputRange, inputRangeClassName),
		track: cx(styles.track, trackClassName),
		activeTrack: cx(styles.activeTrack, activeTrackClassName),
		slider: cx(styles.slider, activeTrackClassName),
		labelContainer: styles.labelContainer,
		maxLabel: cx(styles.maxLabel, labelClassName),
		minLabel: cx(styles.minLabel, labelClassName),
		sliderContainer: styles.sliderContainer,
		valueLabel: styles.valueLabel,
		disabledInputRange: cx(styles.inputRange, styles.disabledTrack),
	}

	return (
		<div className={styles.wrapper}>
			<input
				className={cx(styles.result, resultClassName)}
				value={inputValue}
				disabled={disabled}
				onChange={handleInputChange}
				onBlur={handleInputBlur}
			/>
			<InputRange
				classNames={classNames}
				minValue={min}
				maxValue={max}
				value={value}
				disabled={disabled}
				step={step}
				formatLabel={formatLabel as ComponentPropsWithoutRef<typeof InputRange>['formatLabel']}
				onChange={handleChange}
			/>
		</div>
	)
}
