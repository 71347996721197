import React, { PropsWithChildren } from 'react'
import cx from 'classnames'
import Link from 'next/link'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({
	children,
	size = 'l',
	className,
	href,
	rel = 'noopener noreferrer nofollow',
	...rest
}: PropsWithChildren<IStateProps>) => {
	return (
		<Link
			{...rest}
			className={cx(styles.button, styles[`size${size.toLocaleUpperCase()}`], className, {
				[styles.disabled]: rest.disabled,
			})}
			href={href!}
			rel={rel}
		>
			{children}
		</Link>
	)
}
