import { PagesPath } from 'data/pages-titles'
import _merge from 'lodash.merge'
import Router from 'next/router'
import scrollIntoViewLib from 'scroll-into-view'

export const scrollIntoViewByParent = (node: Element) => {
	const el = node as HTMLElement
	const elLeft = el.offsetLeft + el.offsetWidth
	const parent = el.parentNode as HTMLElement
	const elParentLeft = parent.offsetLeft + parent.offsetWidth

	if (elLeft >= elParentLeft + parent.scrollLeft) {
		parent.scrollLeft = elLeft - elParentLeft
	} else if (elLeft <= parent.offsetLeft + parent.scrollLeft + el.clientWidth) {
		parent.scrollLeft = el.offsetLeft - parent.offsetLeft
	}
}

export default async function scrollToElement(
	selector: string,
	settings?: __ScrollIntoView.Settings,
	afterScroll?: () => void
) {
	const el: HTMLElement | null = document.querySelector(selector)

	const defaultSettings: __ScrollIntoView.Settings = {
		align: {
			top: 0,
			topOffset: 60,
		},
	}

	if (!el) {
		await Router.push(PagesPath.root)

		scrollIntoViewLib(
			document.querySelector(selector)!,
			_merge(defaultSettings, settings),
			afterScroll
		)

		return console.error(`Элемент по селектору "${selector}" не найден`)
	}

	scrollIntoViewLib(el, _merge(defaultSettings, settings), afterScroll)
}
