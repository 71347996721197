import { useEffect, useMemo, useState } from 'react'
import { DepositeTypesResponse } from '@http/api-models/DepositeTypesResponse'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'
import { consoleDebug, isDebug } from '@shared/pipes/is-debug'

type Program = 'Первое сбережение' | 'Премиум сбережения' | 'Базовые сбережения'
type PaymentType = 'MONTHLY' | 'AT_MATURITY'
type Duration = '6' | '12' | '24'
interface ProgramDetails {
	firstDeposite?: boolean
	durations: Array<Duration>
	types: Array<PaymentType>
}

export const programOptions: Array<Program> = [
	'Первое сбережение',
	'Премиум сбережения',
	'Базовые сбережения',
]

interface SendCalcSavingsFormProps {
	amount: number
	duration: Duration
	paymentType: PaymentType
	firstDeposite: boolean
}

const sendCalcSavingsForm = ({
	amount,
	duration,
	paymentType,
	firstDeposite,
}: SendCalcSavingsFormProps): Promise<DepositeTypesResponse[]> => {
	let query = `amount=${amount}&duration=${duration}&interestPaymentType=${paymentType}&sort=profit`

	if (firstDeposite) {
		query += `&firstDeposit=${firstDeposite}`
	}

	if (isDebug()) {
		consoleDebug('[Loan form] - prevent send form')
	}

	return httpService.get(apiUrls.depositeTypes(query))
}

const MIN_STEP = 1 // начальное значение range-input
const MAX_STEP = 241 // количество всех чисел (1 тыс., 5 тыс., 10 тыс., 100тыс и т.д.)
const MIN_AMOUNT = 10000
const THOUSANDS_100 = 100000
const MILLION_1 = 1000000
const MAX_AMOUNT = MILLION_1 * 5

export const getProgramDetails = (amount?: number): ProgramDetails => {
	if (amount && amount < THOUSANDS_100) {
		return {
			firstDeposite: true,
			durations: ['6'],
			types: ['AT_MATURITY'],
		}
	} else if (amount && amount >= THOUSANDS_100 && amount < MILLION_1 + 1) {
		return {
			firstDeposite: true,
			durations: ['6'],
			types: ['MONTHLY', 'AT_MATURITY'],
		}
	} else if (amount && amount >= MILLION_1 + 1 && amount < MILLION_1 * 3) {
		return {
			durations: ['6', '12'],
			types: ['MONTHLY', 'AT_MATURITY'],
		}
	} else {
		return {
			durations: ['12'],
			types: ['MONTHLY', 'AT_MATURITY'],
		}
	}
}

export const durationsOptions: Array<Duration> = ['6', '12', '24']
const durationLabels = { '6': '6 месяцев', '12': '12 месяцев', '24': '24 месяцев' }
export const getDurationLabel = (o: { value: string }): string => durationLabels[o.value] || ''

export const paymentsOptions: Array<PaymentType> = ['MONTHLY', 'AT_MATURITY']
const paymentsLabels = { MONTHLY: 'Ежемесячно', AT_MATURITY: 'В конце срока' }
export const getPaymentsLabel = (o: { value: string }): string => paymentsLabels[o.value] || ''

// получение значения range-input из суммы
export const getStepsFromAmount = (amount: number) => {
	let tempAmount = amount
	let currentStep = 0

	while (tempAmount > 0) {
		if (tempAmount >= 1100000) {
			tempAmount -= 100000
			currentStep++
			continue
		}
		if (tempAmount > 1000000 && tempAmount < 1100000) {
			tempAmount = 1000000
		}

		if (tempAmount >= 310000) {
			tempAmount -= 10000
			currentStep++
			continue
		}

		if (tempAmount > 300000 && tempAmount < 310000) {
			tempAmount = 300000
		}

		if (tempAmount >= 105000) {
			tempAmount -= 5000
			currentStep++
			continue
		}
		if (tempAmount > 100000 && tempAmount < 105000) {
			tempAmount = 100000
		}

		if (tempAmount <= 11000 && tempAmount >= 10000) {
			tempAmount -= 10000
			currentStep++

			if (tempAmount < 1000) {
				break
			}
		}

		if (tempAmount >= 1000) {
			tempAmount -= 1000
			currentStep++

			if (tempAmount < 1000) {
				break
			}
		}
	}

	return currentStep
}

// получение сумму из значения range-input
export const getAmountFromSteps = (steps: number) => {
	let amount = 0

	for (let i = 0; i < steps; i++) {
		if (i === 0) {
			amount += 10000
			continue
		}
		if (i < 91) {
			// до 100000
			amount += 1000
			continue
		}
		if (i < 131) {
			// до 300000
			amount += 5000
			continue
		}
		if (i < 201) {
			// до 1000000
			amount += 10000
			continue
		}

		amount += 100000 // до 5000000
	}

	return amount
}

interface CalculationResult extends DepositeTypesResponse {
	amount: number
}
interface UseCalcValue {
	programDetails: ProgramDetails
	rangeValue: number
	min: number
	max: number
	handleChange: (value: number) => void
	duration: Duration
	setDuration: (value: Duration) => void
	paymentType: PaymentType
	setPaymentType: (value: PaymentType) => void
	calculationResult?: CalculationResult
}
export function useCalc(): UseCalcValue {
	const [amount, setAmount] = useState<number>(MIN_AMOUNT)
	const [rangeValue, setRangeValue] = useState<number>(1)
	const programDetails = getProgramDetails(amount)
	const [duration, setDuration] = useState<Duration>(programDetails.durations[0])
	const [paymentType, setPaymentType] = useState<PaymentType>(programDetails.types[0])
	const [calculationResult, setCalculationResult] = useState<CalculationResult>()

	const handleChange = (steps: number) => {
		setRangeValue(steps)
		setAmount(getAmountFromSteps(steps))
	}

	useEffect(() => {
		if (amount < MIN_AMOUNT) {
			setAmount(MIN_AMOUNT)
		} else if (amount > MAX_AMOUNT) {
			setAmount(MAX_AMOUNT)
		}
		if (!programDetails.durations.includes(duration)) {
			setDuration(programDetails.durations[0])
		}
		if (!programDetails.types.includes(paymentType)) {
			setPaymentType(programDetails.types[0])
		}
	}, [amount, duration, programDetails])

	useEffect(() => {
		const updater = async () => {
			const result = await sendCalcSavingsForm({
				amount,
				duration,
				paymentType,
				firstDeposite: programDetails.firstDeposite!,
			})

			const { maxProfitResult } = result.reduce(
				(acc, item) => {
					const sum = (item.annualInterestRate ?? 0) + (item.cashback ?? 0)

					if (sum > acc.max) {
						acc.max = sum
						acc.maxProfitResult = item
					}

					return acc
				},
				{ max: 0, maxProfitResult: null as DepositeTypesResponse | null }
			)

			setCalculationResult({ ...maxProfitResult!, amount })
		}
		const timeout = setTimeout(updater, 250)
		return () => {
			clearTimeout(timeout)
		}
	}, [programDetails.firstDeposite, amount, duration, paymentType])

	const result = useMemo<UseCalcValue>(
		() => ({
			min: MIN_STEP,
			max: MAX_STEP,
			programDetails,
			rangeValue,
			handleChange,
			duration,
			setDuration,
			paymentType,
			setPaymentType,
			calculationResult,
		}),
		[programDetails, amount, duration, paymentType, calculationResult]
	)
	return result
}
