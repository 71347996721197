import React, { useMemo, useState } from 'react'
import ReactSelect from 'react-select'
import styled from 'styled-components'

import { DropdownIndicator, IndicatorSeparator, NoOptionsMessage } from './components'

interface SelectProps {
	inputId?: string
	options?: string[]
	value?: string
	onChange?: (value: string) => void
	getOptionLabel?: (option: Option) => string
}

interface Option {
	value: string
	label: string
}

const components = {
	IndicatorSeparator,
	DropdownIndicator,
}

export function Select({
	inputId,
	options = [],
	value,
	onChange,
	getOptionLabel,
}: SelectProps): JSX.Element {
	const defaultMenuIsOpen = false
	const [menuIsOpen, setMenuIsOpen] = useState(defaultMenuIsOpen)

	const optionsMapped = useMemo<Option[]>(
		() => options.map((o) => ({ value: o, label: o })),
		[options]
	)
	const valueMapped = useMemo(
		() => optionsMapped.find((o) => o.value === value) || { value: value, label: value },
		[optionsMapped, value]
	)

	return (
		<SelectStyled
			inputId={inputId}
			options={optionsMapped}
			value={valueMapped}
			noOptionsMessage={NoOptionsMessage}
			isClearable={false}
			isSearchable={false}
			onChange={(option: Option) => onChange?.(option?.value ?? undefined)}
			components={components}
			classNamePrefix='styledSelect'
			defaultMenuIsOpen={defaultMenuIsOpen}
			$menuIsOpen={menuIsOpen}
			onMenuClose={() => setMenuIsOpen(false)}
			onMenuOpen={() => setMenuIsOpen(true)}
			getOptionLabel={getOptionLabel}
		/>
	)
}

const SelectStyled = styled(ReactSelect)<any>(({ theme, $menuIsOpen }) => ({
	'& .styledSelect__control': {
		border: 'none',
		outline: 'none',
		boxShadow: 'none',
		backgroundColor: $menuIsOpen ? 'rgba(0, 0, 0, 0.13)' : theme.colors.lightmint,
		cursor: 'pointer',
		borderRadius: 8,
	},
	'& .styledSelect__control:hover': {
		backgroundColor: 'rgba(0, 0, 0, 0.13)',
	},
	'& .styledSelect__indicator': {
		paddingRight: 18,
	},
	'& .styledSelect__value-container': {
		paddingLeft: 12,
		paddingRight: 0,
	},
	'& .styledSelect__single-value': {
		...theme.typography.text18_24,
		margin: 0,
	},
	'& .styledSelect__menu': {
		marginTop: 4,
		boxShadow: '0px 0px 2px 0px hsl(0deg 0% 0% / 10%), 0 5px 9px hsl(0deg 0% 0% / 10%)',
	},
	'& .styledSelect__option': {
		...theme.typography.text20_26,
	},
	'& .styledSelect__option:hover': {
		backgroundColor: theme.colors.lightmint,
	},
	'& .styledSelect__option--is-selected, & .styledSelect__option--is-selected:hover': {
		backgroundColor: 'rgba(0, 0, 0, 0.13) !important',
		color: 'inherit',
	},
	'& .styledSelect__option--is-focused': {
		backgroundColor: 'transparent',
		color: 'inherit',
	},
	[theme.mediaQueries.mediaMinWidthExtraMobile]: {
		'& .styledSelect__value-container': {
			paddingLeft: 12,
			paddingTop: 16,
			paddingBottom: 16,
			paddingRight: 0,
		},
		'& .styledSelect__single-value': {
			...theme.typography.text24_32,
		},
	},
}))
