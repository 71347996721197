import React from 'react'
import { InvestorPopupFormProvider } from '@providers/investor-popup-form'
import { FooterNewLoanPromo, FooterNewLP } from '@shared/components/footer'
import { scrollToApplicationForm } from '@shared/components/forms/application/cross'
import { scrollToEstateAgentsForm } from '@shared/components/forms/estate-agents'
import { scrollToLoanForm } from '@shared/components/forms/new-loan'
import { PageLayout } from '@shared/components/page-layout'
import { PageLayoutV2 } from '@shared/components/page-layout-v2'
import { PersonalFooterExtra } from '@shared/components/personal-footer-extra'
import { PersonalFooterIpoteka } from '@shared/components/personal-footer-ipoteka'
import { PersonalFooterNerez } from '@shared/components/personal-footer-nerez'
import { getHeaderActionButton } from '@shared/components/top-menu/shared/header-action-button'
import { NextPageMixedProps } from '@shared/models/next-fc'
import { composeProviders } from '@shared/pipes/compose-providers'
import { yaCounter } from '@shared/pipes/ya-counter'
import chalk from 'chalk'

import { goToConsultLink } from '../../screens/investors-fond/SectionConsult'
import { regionalPages } from '../regional-pages'
import { goToCalculatorLink } from '@blocks/BlockInvestCalculator'

type PagesProps = {
	[path in RegionalPagesPath]: NextPageMixedProps<any, any>
}

export enum RegionalPagesPath {
	root = '/',
	personalNaZhile = 'personal/na-zhile',
	personalNaObrazovanie = 'personal/na-obrazovanie',
	personalNaRemont = 'personal/na-remont',
	personalNaStroitelstvo = 'personal/na-stroitelstvo',
	personalNaPokupkuDoma = 'personal/na-pokupku-doma',
	personalNaRefinansirovanie = 'personal/na-refinansirovanie',
	personalNaIpotekuBezDohoda = 'personal/ipoteka-bez-podtverzhdeniya-dohoda',
	personalNaIpotekuNerez = 'personal/ipoteka-dlya-nerezidentov',
	invest = 'invest',
	loan = 'loan',
	loanMkk = 'loan/mkk',
	podZalogKv = 'pod-zalog-kvartiry',
	podZalogNedv = 'pod-zalog-nedvizhimosti',
}

export const regionalPagesProps = {
	[RegionalPagesPath.root]: {
		headProps: {
			title:
				'Кредит под залог недвижимости в %city_pr% — На любые цели | Выгодные условия и честные процентные ставки',
			description:
				'Получите кредит под залог недвижимости (квартиры) на выгодных условиях в %city_pr%. ► До 2 млн в день обращения ► Честные процентные ставки ► Без скрытых коммисий и платежей ► Быстрое онлайн оформление',
			ogImageName: 'graph-loan.png',
			advCakeEvents: [
				{
					pageType: 1,
					user: {
						email: '', // email в виде хеша или уникальный идентификатор, данное поле можно оставить пустым
					},
				},
				{
					pageType: 3,
					user: {
						email: '', // email в виде хеша или уникальный идентификатор, данное поле можно оставить пустым
					},
					currentLead: {
						id: 'REAL_ESTATE_LOAN', // ID продукта, если такой есть, если нет, оставляем пустым
						name: 'Кредит', //наименование услуги лида
					},
				},
			],
		},
		newLayoytV2: true,
		layout: PageLayoutV2,
	},
	[RegionalPagesPath.personalNaZhile]: {
		layout: (props) =>
			PageLayout({
				...props,
				menuProps: {
					...props.menuProps,
					EndAdornment: getHeaderActionButton({
						buttonText: 'Оставить заявку',
						onClick: scrollToApplicationForm,
					}),
				},
				footerProps: {
					isNavsHidden: true,
					extraContent: <PersonalFooterExtra />,
				},
			}),
		headProps: {
			title: 'Взять деньги заём под залог квартиры в %city_pr%',
			description:
				'Выдаём до 15 000 000 рублей под залог квартиры и другого жилья в %city_pr% ✅ срочно ✅ без справок ✅ сделка онлайн ✅ без посещения МФЦ ► Получите заём в Credit.Club за 1 день.',
			ogImageName: 'personal-na-zhile.jpg',
		},
	},
	[RegionalPagesPath.personalNaObrazovanie]: {
		layout: (props) =>
			PageLayout({
				...props,
				menuProps: {
					...props.menuProps,
					EndAdornment: getHeaderActionButton({
						buttonText: 'Оставить заявку',
						onClick: scrollToApplicationForm,
					}),
				},
				footerProps: {
					isNavsHidden: true,
					extraContent: <PersonalFooterExtra />,
				},
			}),
		headProps: {
			title: 'Взять кредит на образование в %city_pr% - деньги онлайн на обучение онлайн 2021',
			description:
				'Образовательный кредит в %city_pr% на обучение ► До 15 000 000 рублей ► Любое учебное заведение ► Деньги через 1-2 дня ► Комфортная альтернатива кредиту в банке.',
			ogImageName: 'personal-na-obrazovanie.jpg',
		},
	},
	[RegionalPagesPath.personalNaRemont]: {
		layout: (props) =>
			PageLayout({
				...props,
				menuProps: {
					...props.menuProps,
					EndAdornment: getHeaderActionButton({
						buttonText: 'Оставить заявку',
						onClick: scrollToApplicationForm,
					}),
				},
				footerProps: {
					isNavsHidden: true,
					extraContent: <PersonalFooterExtra />,
				},
			}),
		headProps: {
			title: 'Кредит на ремонт в %city_pr% - онлайн заявка на заём наличными 2021',
			description:
				'Кредиты на ремонт квартиры или частного дома в %city_pr% до 15 000 000 рублей  ► Без комиссий и посещения офиса ► Лояльны к кредитной истории ► Оставьте заявку и получите решение за 1 день.\n',
			ogImageName: 'personal-na-remont.jpg',
		},
	},
	[RegionalPagesPath.personalNaStroitelstvo]: {
		headProps: {
			title:
				'Кредит на строительство частного дома в %city_pr% - оформить кредит на строительство загородного дома без первоначального взноса онлайн',
			description:
				'Кредиты на строительство частного дома в %city_pr% без первоначального взнос до 15 000 000 рублей  ► Без комиссий и посещения офиса ► Лояльны к кредитной истории ► Оставьте заявку и получите решение за 1 день.',
			ogImageName: 'personal-na-stroitelstvo.png',
		},
		layout: (props) =>
			PageLayout({
				...props,
				menuProps: {
					...props.menuProps,
					EndAdornment: getHeaderActionButton({
						buttonText: 'Оставить заявку',
						onClick: scrollToApplicationForm,
					}),
				},
				footerProps: {
					isNavsHidden: true,
					extraContent: <PersonalFooterExtra />,
				},
			}),
	},
	[RegionalPagesPath.personalNaPokupkuDoma]: {
		headProps: {
			title: 'Кредит на загородный дом в %city_pr% — взять ипотеку на частный дом',
			description:
				'Кредиты на загородный дом в %city_pr% до 15 000 000 рублей  ► Без комиссий и посещения офиса ► Лояльны к кредитной истории ► Оставьте заявку и получите решение за 1 день.',
			ogImageName: 'personal-na-pokupku-doma.png',
		},
		layout: (props) =>
			PageLayout({
				...props,
				menuProps: {
					...props.menuProps,
					EndAdornment: getHeaderActionButton({
						buttonText: 'Оставить заявку',
						onClick: scrollToApplicationForm,
					}),
				},
				footerProps: {
					isNavsHidden: true,
					extraContent: <PersonalFooterExtra />,
				},
			}),
	},
	[RegionalPagesPath.personalNaRefinansirovanie]: {
		headProps: {
			title:
				'Рефинансирование кредитов онлайн в %city_pr% - Возьмите кредит на погашение кредитов других банков',
			description:
				'Кредиты на погашение кредитов других банков в %city_pr% до 15 000 000 рублей  ► Без комиссий и посещения офиса ► Лояльны к кредитной истории ► Оставьте заявку и получите решение за 1 день.',
			ogImageName: 'personal-na-refinansirovanie.png',
		},
		layout: (props) =>
			PageLayout({
				...props,
				menuProps: {
					...props.menuProps,
					EndAdornment: getHeaderActionButton({
						buttonText: 'Оставить заявку',
						onClick: scrollToApplicationForm,
					}),
				},
				footerProps: {
					isNavsHidden: true,
					extraContent: <PersonalFooterExtra />,
				},
			}),
	},
	[RegionalPagesPath.personalNaIpotekuBezDohoda]: {
		headProps: {
			title: 'Ипотека без подтверждения дохода в %city_pr%',
			description: 'Получите деньги на покупку квартиры быстро и без походов в офис',
			// ogImageName: 'personal-na-refinansirovanie.png',
		},
		layout: (props) =>
			PageLayout({
				...props,
				menuProps: {
					...props.menuProps,
					EndAdornment: getHeaderActionButton({
						buttonText: 'Оставить заявку',
						onClick: scrollToEstateAgentsForm,
					}),
				},
				footerProps: {
					isNavsHidden: true,
					extraContent: <PersonalFooterIpoteka />,
				},
			}),
	},
	[RegionalPagesPath.personalNaIpotekuNerez]: {
		headProps: {
			title: 'Ипотека для нерезидентов в %city_pr%',
			description: 'Получите деньги на покупку квартиры быстро и без походов в офис',
			// ogImageName: 'personal-na-refinansirovanie.png',
		},
		layout: (props) =>
			PageLayout({
				...props,
				menuProps: {
					...props.menuProps,
					EndAdornment: getHeaderActionButton({
						buttonText: 'Оставить заявку',
						onClick: scrollToEstateAgentsForm,
					}),
				},
				footerProps: {
					isNavsHidden: true,
					extraContent: <PersonalFooterNerez />,
				},
			}),
	},
	[RegionalPagesPath.invest]: {
		layout: composeProviders(
			[
				[
					InvestorPopupFormProvider,
					{
						title: <>Узнайте больше о&nbsp;сбережениях</>,
						subtitle: <>Оставьте заявку, мы&nbsp;перезвоним и&nbsp;расскажем о&nbsp;сбережениях</>,
						buttonText: 'Жду звонка',
						onStartSubmitForm: () => yaCounter.utils.reachGoal('forms_orderCnt'),
					},
				],
			],
			(ownProps: any) =>
				PageLayout({
					...ownProps,
					menuProps: {
						...ownProps?.menuProps,
						EndAdornment: getHeaderActionButton({
							buttonText: 'Узнать о сбережениях',
							onClick: goToCalculatorLink,
						}),
					},
				})
		),
		headProps: {
			title: 'Сбережения с гарантией выплат',
			description:
				'Сбережения с гарантией регулярных выплат. Высокая доходность. Под надзором ЦБ РФ и СРО. Надёжно: сбережения направляются только в залоговые займы. Есть резервные фонды.',
			ogTitle: 'Сбережения с гарантией выплат',
			ogImageName: 'new-invest-og-img.jpg',
			advCakeEvents: [
				{
					pageType: 3,
					user: {
						email: '', // email в виде хеша или уникальный идентификатор, данное поле можно оставить пустым
					},
					currentLead: {
						id: '', // ID продукта, если такой есть, если нет, оставляем пустым
						name: 'Сбережения', //наименование услуги лида
					},
				},
			],
		},
	},
	[RegionalPagesPath.podZalogKv]: {
		layout: (props) =>
			PageLayout({
				...props,
				menuProps: {
					...props.menuProps,
					EndAdornment: getHeaderActionButton({
						buttonText: 'Оставить заявку',
						onClick: scrollToLoanForm,
					}),
				},
			}),
		headProps: {
			title: 'Взять деньги заём под залог квартиры в %city_pr%',
			description:
				'Выдаём до 15 000 000 рублей под залог квартиры и другого жилья в %city_pr% ✅ срочно ✅ без справок ✅ сделка онлайн ✅ без посещения МФЦ ► Получите заём в Credit.Club за 1 день.',
			ogImageName: 'loan-apartment.jpg',
		},
	},
	[RegionalPagesPath.podZalogNedv]: {
		layout: (props) =>
			PageLayout({
				...props,
				menuProps: {
					...props.menuProps,
					EndAdornment: getHeaderActionButton({
						buttonText: 'Оставить заявку',
						onClick: scrollToApplicationForm,
					}),
				},
			}),
		headProps: {
			title: 'Взять деньги в заём под залог недвижимости в %city_pr%',
			description:
				'Деньги под залог недвижимости в %city_pr% от Credit.Club ► Получите заём — до 15 млн рублей ► Решение за 1 день ► Оформляем сделку онлайн ✅ срочно ✅ без справок.',
			ogImageName: 'real-estate.jpg',
		},
	},
	[RegionalPagesPath.loan]: {
		layout: PageLayout,
		layoutProps: {
			menuProps: {
				hideLinksList: true,
			},
			customFooter: <FooterNewLP />,
		},
		headProps: {
			title: 'Деньги на любые цели по честной ставке',
			description:
				'От 500 тысяч рублей ► Конкурентная ставка ► До 2 млн в день обращения ► Без скрытых комиссий и платежей',
			ogImageName: 'loan.png',
			advCakeEvents: [
				{
					pageType: 3,
					user: {
						email: '', // email в виде хеша или уникальный идентификатор, данное поле можно оставить пустым
					},
					currentLead: {
						id: 'REAL_ESTATE_LOAN', // ID продукта, если такой есть, если нет, оставляем пустым
						name: 'Кредит', //наименование услуги лида
					},
				},
			],
		},
	},
	[RegionalPagesPath.loanMkk]: {
		layout: (props) =>
			PageLayoutV2({
				...props,
				menuProps: { ...props.menuProps, hideLinksList: true, disableRedirectOnLogo: true },
				footerV2Props: {
					hideAppDescription: true,
					hideInfo: true,
					hideFooterMenu: true,
					template: 'loanMkk',
				},
			}),
		headProps: {
			title: 'Деньги на любые цели по честной ставке',
			description:
				'От 500 тысяч рублей ► Конкурентная ставка ► До 2 млн в день обращения ► Без скрытых комиссий и платежей',
			ogImageName: 'loan.png',
		},
	},
}

const keys = Object.keys(regionalPagesProps)
const hasDiff = (() => {
	const sortWithStr = (arr: any[]) => [...arr].sort().join('|')
	return sortWithStr(keys) !== sortWithStr(regionalPages)
})()
if (keys.length !== regionalPages.length || hasDiff) {
	chalk.green(
		`Regions and pages props is not equal, page props: ${keys.join(
			', '
		)} regional pages: ${regionalPages.join(', ')} `
	)
}
