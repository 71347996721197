import React from 'react'
import { components, DropdownIndicatorProps } from 'react-select'
import styled from 'styled-components'

import SelectChevron from './selectChevron.svg'

export const NoOptionsMessage = () => 'Не найдено'

export const IndicatorSeparator = () => null

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
	return (
		<components.DropdownIndicator {...props}>
			<SelectChevronStyled $isOpen={props.selectProps.menuIsOpen} />
		</components.DropdownIndicator>
	)
}

interface SelectChevronStyledProps {
	$isOpen?: boolean
}
const SelectChevronStyled = styled(SelectChevron)<SelectChevronStyledProps>(({ $isOpen }) => ({
	width: 12,
	height: 6,
	transform: $isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)',
	opacity: 0.5,
}))
