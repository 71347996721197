import React, { ReactNode, useMemo } from 'react'
import { InputRange } from '@blockComponents/InputRange'
import { ANALITYCS_InvestorFordStartInvestingButtonIds } from '@constants/analitycs-ids'
import { BlockAnchors } from '@constants/forms'
import { Button } from '@shared/components/button'
import { Section } from '@shared/components/section'
import { Spoiler } from '@shared/components/spoiler'
import { ButtonLink } from '@shared/componentsStyled/Button'
import { Link } from '@shared/componentsStyled/Link'
import { Select } from '@shared/componentsStyled/Select'
import { ExternalPaths } from '@shared/menu-items/paths'
import { changeDocumentHash } from '@shared/pipes/dom'
import scrollToElement from '@shared/pipes/scroll'
import { yaCounter } from '@shared/pipes/ya-counter'
import { breaksHundreds, formatCalcSum } from '@shared/utils/helpers'
import styled, { CSSObject } from 'styled-components'

import { getAmountFromSteps, getDurationLabel, getPaymentsLabel, useCalc } from './data'

const logInvestCalc = (): void => yaCounter.utils.reachGoal('invest_calculator')
const logInvestLead = (): void => yaCounter.utils.reachGoal('invest_lead')
const logInvestTg = (): void => yaCounter.utils.reachGoal('invest_business_tg')

export interface OwnProps {
	formLink?: string
	warn?: ReactNode
	annualInterestRate?: [string, string]
	onButtonClick?: () => void
}

export const goToCalculatorLink = () => {
	scrollToElement(`#${BlockAnchors.calculator}`, { time: 0 }, () => {
		changeDocumentHash(BlockAnchors.calculator)
	})
}

export function BlockInvestCalculator({
	formLink,
	annualInterestRate = ['', ''],
	warn,
	onButtonClick,
}: OwnProps): JSX.Element {
	const {
		rangeValue,
		handleChange,
		programDetails,
		duration,
		min,
		max,
		setDuration,
		paymentType,
		setPaymentType,
		calculationResult,
	} = useCalc()

	const { percent, overall, monthlyResult } = useMemo<{
		percent: number
		overall: number
		monthlyResult: number
	}>(() => {
		if (!calculationResult) {
			return { percent: 0, overall: 0, monthlyResult: 0 }
		}
		return {
			percent: (calculationResult.annualInterestRate ?? 0) + (calculationResult.cashback ?? 0),
			overall: (calculationResult.amount ?? 0) + (calculationResult.profit ?? 0),
			monthlyResult: (calculationResult.profit ?? 0) / +(duration ?? 0),
		}
	}, [calculationResult, duration])

	return (
		<>
			<Section id={BlockAnchors.calculator}>
				<ViewRoot>
					<TextTitle>Рассчитайте доходность</TextTitle>
					<TextSub noMargin={!!warn}>
						Для нового клиента повышенная доходность до 1 млн рублей включительно.
					</TextSub>
					{warn && <Warn>{warn}</Warn>}
					<ViewContent>
						<ViewCalc>
							<InputRow>
								<TextLabel htmlFor='investFondAmount'>Сумма сбережений, рублей</TextLabel>
								<InputRange
									value={rangeValue}
									onChange={handleChange}
									step={1}
									max={max}
									min={min}
									formatLabel={(rangeValue) =>
										`${formatCalcSum(getAmountFromSteps(+rangeValue))} рублей`
									}
									labelClassName='calcLabel'
									activeTrackClassName='calcTrack'
									inputRangeClassName='calcRange'
									resultClassName='calcResult'
								/>
							</InputRow>
							<ViewCalcRow>
								<ViewCalcCol>
									<TextLabel htmlFor='investFondDuration'>Срок</TextLabel>
									<Select
										inputId='investFondDuration'
										value={duration}
										onChange={(v) => setDuration(v as Parameters<typeof setDuration>[0])}
										options={programDetails.durations}
										getOptionLabel={getDurationLabel}
									/>
								</ViewCalcCol>
								<ViewCalcCol>
									<TextLabel htmlFor='investFondType'>Тип выплат</TextLabel>
									<Select
										inputId='investFondType'
										value={paymentType}
										onChange={(v) => setPaymentType(v as Parameters<typeof setPaymentType>[0])}
										options={programDetails.types}
										getOptionLabel={getPaymentsLabel}
									/>
								</ViewCalcCol>
							</ViewCalcRow>
							<TextNote style={{ marginTop: 14 }}>
								Расчет является предварительным. Не является публичной офертой.
							</TextNote>
						</ViewCalc>
						<ViewRes>
							<ViewResRow>
								<ViewResItem>
									<TextLabelSmall>Доходность</TextLabelSmall>
									<TextResItem>{percent.toString().replace('.', ',')}% годовых</TextResItem>
								</ViewResItem>
								<ViewResItem>
									<TextLabelSmall>НДФЛ</TextLabelSmall>
									<TextResItem>
										<Link
											href='https://percent.credit.club/nalogooblozhenie-sberezhenij/'
											target='_blank'
											rel='noopener noreferrer nofollow'
										>
											0%
										</Link>
									</TextResItem>
								</ViewResItem>
								<ViewResItem>
									<TextLabelSmall>Доход за месяц</TextLabelSmall>
									<TextResItem>{breaksHundreds(monthlyResult.toFixed())} ₽</TextResItem>
								</ViewResItem>
							</ViewResRow>
							{onButtonClick ? (
								<FormButton
									type='button'
									onClick={() => {
										logInvestLead()
										onButtonClick()
									}}
								>
									Открыть сбережение
								</FormButton>
							) : (
								<ButtonInvest
									target='_blank'
									rel='noopener noreferrer nofollow'
									href={formLink}
									id={ANALITYCS_InvestorFordStartInvestingButtonIds(3)}
									onClick={logInvestCalc}
								>
									Вложить
								</ButtonInvest>
							)}
							<BiggerTextNote>
								или{' '}
								<Link
									style={{ cursor: 'pointer' }}
									target='_blank'
									rel='noopener noreferrer nofollow'
									href='https://t.me/invest_creditclub/1900'
								>
									узнать подробнее в чате Телеграм
								</Link>
							</BiggerTextNote>
						</ViewRes>
					</ViewContent>
				</ViewRoot>
				{/* <Disclaimer>
					<DisclaimerText style={{ fontWeight: 'bold', marginBottom: 4 }}>
						Хотите инвестировать со счёта ИП/ЮЛ?
					</DisclaimerText>
					<DisclaimerText>
						<Link
							href='https://t.me/invest_creditclub/6844'
							style={{ cursor: 'pointer' }}
							onClick={logInvestTg}
							target='_blank'
							rel='noopener noreferrer nofollow'
						>
							Узнайте подробнее
						</Link>{' '}
						о нашем продукте для бизнес-инвесторов
					</DisclaimerText>
				</Disclaimer> */}
			</Section>
			<Section>
				<ViewSpoiler>
					<Spoiler buttonText='Подробные условия' buttonTextAlign='right'>
						<noindex>
							Услуги физическим лицам оказывает Кредитный потребительский кооператив «Фонд
							Ипотечного Кредитования» (Свердловская область, г. Екатеринбург, ул. Малышева, стр.
							51, оф. 25/07, ОГРН 1196658040677 ИНН 6678102434,{' '}
							<a
								className='main-link'
								href={ExternalPaths.loanfund}
								target='_blank'
								rel='noopener noreferrer nofollow'
							>
								https://loanfund.ru/
							</a>
							) в рамках Федерального закона №190-ФЗ от 18.07.2009 года путем заключения договора
							передачи личных сбережений на следующих условиях:
							<br />
							• Сумма передаваемых личных сбережений от 10 000 до 150 000 000 рублей.
							<br />• Ставка процентов за пользование кооперативом привлечёнными денежными
							средствами члена Кооператива — от {annualInterestRate[0]}% до {annualInterestRate[1]}%
							годовых, определяется индивидуально исходя из выбранной программы привлечения денежных
							средств членов Кооператива. В случае невыполнения условия договора клиентом возможен
							пересчет по ставке 0.1% годовых.
							<br />
							• Максимальный срок возврата личных сбережений — от 6 до 60 месяцев. Выплата процентов
							осуществляется ежемесячно или при возврате суммы сбережений.
							<br />
							<br />
							Кредитный потребительский кооператив оказывает услуги исключительно членам кредитного
							кооператива (пайщикам). При вступлении в кооператив вносится обязательный паевой взнос
							в размере 50 рублей. Член кредитного кооператива (пайщика) обязаны нести субсидиарную
							ответственность по обязательствам кооператива в пределах невнесенной части
							дополнительного взноса каждого из членов кредитного кооператива (пайщиков).
							<br />
							<br />
							Сервис цифровых решений предоставляется участником проекта инновационного центра
							«Сколково» ООО «Кредит.Клаб» (Свердловская область, г. Екатеринбург, ул. Малышева,
							стр. 51, оф. 25/07, ОГРН 1196658084743 ИНН 6678105594,{' '}
							<a
								className='main-link'
								href='https://credit.club'
								target='_blank'
								rel='noopener noreferrer nofollow'
							>
								https://credit.club/
							</a>
							). <br />
							Не является публичной офертой.
							<br />
							<br />
							Продукт «Сбережение» не относится к инвестициям, предусмотренным федеральным законом
							от 02.08.2019 № 259-ФЗ «О привлечении инвестиций с использованием инвестиционных
							платформ и о внесении изменений в отдельные законодательные акты Российской федерации»
						</noindex>
					</Spoiler>
				</ViewSpoiler>
			</Section>
		</>
	)
}

const ViewRoot = styled.div(({ theme }) => ({
	border: '1px solid rgba(216, 234, 228, 0.25)',
	// borderTopLeftRadius: 40,
	// borderTopRightRadius: 40,
	borderRadius: 40,
	marginBottom: 20,
	boxShadow: '0px 10px 30px rgba(163, 198, 186, 0.25)',
	paddingTop: 20,
	paddingLeft: 20,
	paddingRight: 20,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		paddingTop: 40,
		paddingLeft: 60,
		paddingRight: 60,
	},
}))

const Disclaimer = styled.div(({ theme }) => ({
	borderBottomLeftRadius: 40,
	borderBottomRightRadius: 40,
	paddingTop: 16,
	paddingLeft: 20,
	paddingRight: 20,
	paddingBottom: 16,
	marginBottom: 15,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		paddingLeft: 60,
		paddingRight: 60,
	},
	backgroundColor: theme.colors.lightmint,
}))

const DisclaimerText = styled.h3(({ theme }) => ({
	...theme.typography.text24_32,
}))

const TextTitle = styled.h2(({ theme }) => ({
	...theme.typography.header30_36,
	marginBottom: 20,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.header56_60,
		marginBottom: 20,
	},
}))

const TextSub = styled.div<{ noMargin?: boolean }>(({ theme, noMargin }) => ({
	...theme.typography.text18_24,
	marginBottom: noMargin ? 10 : 20,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text24_32,
		marginBottom: noMargin ? 10 : 40,
	},
}))

const Warn = styled.div(({ theme }) => ({
	...theme.typography.text18_24,
	backgroundColor: '#fff6b3',
	padding: 8,
	borderRadius: 8,
	marginBottom: 20,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text24_32,
		marginBottom: 20,
	},
}))

const ViewContent = styled.div(
	({ theme }) =>
		({
			display: 'flex',
			flexDirection: 'column',
			marginBottom: 20,
			[theme.mediaQueries.mediaMinWidthMobile]: {
				flexDirection: 'row',
				marginBottom: 50,
			},
		} as CSSObject)
)

const ViewCalc = styled.div(
	({ theme }) =>
		({
			flex: 1,
			marginRight: 0,
			display: 'flex',
			flexDirection: 'column',
			[theme.mediaQueries.mediaMinWidthMobile]: {
				marginRight: 80,
			},
			'& .calcLabel': {
				...theme.typography.text12_16,
			},
			'& .calcTrack': {
				backgroundColor: theme.colors.lightbrown,
			},
			'& .calcRange': {
				backgroundColor: theme.colors.peach,
			},
			'& .calcResult': {
				...theme.typography.text18_24,
				backgroundColor: theme.colors.lightmint,
				height: 40,
				paddingBottom: 4,
				[theme.mediaQueries.mediaMinWidthMobile]: {
					...theme.typography.text24_32,
					height: 64,
					paddingBottom: 0,
				},
			},
		} as CSSObject)
)

const InputRow = styled.div(() => ({
	marginBottom: 10,
}))

const ViewRes = styled.div(
	({ theme }) =>
		({
			flex: 1,
			width: '100%',
			backgroundColor: theme.colors.lightmint,
			borderRadius: 8,
			alignSelf: 'flex-end',
			height: 'min-content',
			padding: '10px 15px',
			display: 'flex',
			flexDirection: 'column',
			[theme.mediaQueries.mediaMinWidthMobile]: {
				padding: '20px 30px',
			},
		} as CSSObject)
)

const TextLabel = styled.label(({ theme }) => ({
	...theme.typography.text14_18,
	opacity: 0.5,
	display: 'block',
	marginBottom: 5,
	whiteSpace: 'normal',

	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text18_24,
		marginBottom: 10,
		whiteSpace: 'nowrap',
	},
}))

const TextLabelSmall = styled.label(({ theme }) => ({
	...theme.typography.text14_18,
	opacity: 0.5,
	display: 'block',
	marginBottom: 5,
	whiteSpace: 'normal',

	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text18_24,
		whiteSpace: 'nowrap',
	},
}))

const ViewCalcRow = styled.div(
	({ theme }) =>
		({
			display: 'flex',
			flexDirection: 'column',
			[theme.mediaQueries.mediaMinWidthMobile]: {
				flexDirection: 'row',
			},
		} as CSSObject)
)

const ViewCalcCol = styled.div(({ theme }) => ({
	flex: 1,
	marginRight: 0,
	'&:first-child': {
		marginBottom: 10,
	},
	[theme.mediaQueries.mediaMinWidthMobile]: {
		'&:first-child': {
			marginRight: 20,
			marginBottom: 0,
		},
	},
}))

const ViewResRow = styled.div(
	({ theme }) =>
		({
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			[theme.mediaQueries.mediaMinWidthMobile]: {
				display: 'grid',
				gridTemplateColumns: 'auto auto auto',
				gap: 16,
				flexDirection: 'row',
			},
		} as CSSObject)
)

const ViewResItem = styled.div(({ theme }) => ({
	flex: 1,
	marginRight: 0,
	display: 'block',
}))

const TextResItem = styled.div<{ blue?: boolean }>(({ theme, blue }) => ({
	...theme.typography.text18_24,
	color: blue ? theme.colors.main : undefined,
	marginBottom: 10,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text24_32,
		marginBottom: 28,
	},
}))

const ButtonInvest = styled(ButtonLink)(({ theme }) => ({
	marginBottom: 20,
	alignSelf: 'flex-start',
	[theme.mediaQueries.mediaMinWidthMobile]: {
		marginBottom: 20,
	},
}))

const FormButton = styled(Button)(({ theme }) => ({
	marginBottom: 20,
	alignSelf: 'flex-start',
	[theme.mediaQueries.mediaMinWidthMobile]: {
		marginBottom: 20,
	},
}))

const TextNote = styled.p(({ theme }) => ({
	opacity: 0.5,
	...theme.typography.text12_16,
	marginBottom: 20,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		marginBottom: 0,
	},
}))

const BiggerTextNote = styled.p(({ theme }) => ({
	color: theme.colors.black50,
	...theme.typography.text14_18,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text18_24,
	},
}))

const ViewSpoiler = styled.div(({ theme }) => ({
	...theme.typography.text12_16,
	opacity: 0.5,
	marginBottom: 50,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		marginBottom: 100,
	},
}))
